.link() {
  text-decoration: var(--link-text-decoration);
  font-weight: var(--link-font-weight);

  &:link {
    color: var(--link-color);
  }

  &:visited {
    color: var(--link-visited-color);
  }

  &:hover,
  &:focus {
    color: var(--link-color--hover);
    text-decoration: var(--link-text-decoration--hover);
  }

  &:active {
    color: var(--link-color--active);
    text-decoration: var(--link-text-decoration--active);
  }
}

.link-dark() {
  text-decoration: var(--link-dark-text-decoration);
  font-weight: var(--link-dark-font-weight);

  &:link {
    color: var(--link-dark-color);
  }

  &:visited {
    color: var(--link-dark-visited-color);
  }

  &:hover,
  &:focus {
    color: var(--link-dark-color--hover);
    text-decoration: var(--link-dark-text-decoration--hover);
  }

  &:active {
    color: var(--link-dark-color--active);
    text-decoration: var(--link-dark-text-decoration--active);
  }
}
