
.am-badge,
.plugin-manager__category-label:link,
.plugin-manager__category-label:visited {
    display: inline-block;
    border: 1px solid #ccc;
    border: 1px solid var(--plugin-manager-category-link-border-color);
    background-color: #fbfbfb;
    background-color: var(--plugin-manager-category-link-bg-color);
    color: #333;
    color: var(--plugin-manager-category-text-color);
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0 0.5rem;
    margin: 0 0.25rem 0.25rem 0;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    transition: all 0.15s ease-in-out;
}

.plugin-manager__category-label:link,
.plugin-manager__category-label:visited {
  &:hover,
  &:focus,
  &:active {
    background-color: #f2f2f2;
    background-color: var(--plugin-manager-category-link-bg-color--hover);
    border-color:  #333;
    border-color:  var(--plugin-manager-category-link-border-color--hover);
    color: #333;
    color: var(--plugin-manager-category-link-color--hover);
  }
}

.am-badge {
  display: inline;
}
